.card-header {
  width: 100%;
	height: 100%;
	background-color: #212338;
}
.remove-bg {
  filter: brightness(0);
  mix-blend-mode: multiply;
}
.social{
  height: 100%;
	display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
	background-color: #212338;
  font-weight: 600;
}
#LinkedInLogo{
	top: 15px;
  height: 50px;
}
#GitHubLogo{
  top: 15px;
  height: 50px;
}
#FBLogo{
	position: absolute;
  top: 15px;
  right: 200px;
  height: 50px;
  align-items: center;
}
#IGLogo{
	position: absolute;
  top: 15px;
  right: 150px;
  height: 50px;
  align-items: center;
}
#TwitterLogo{
	position: absolute;
  top: 15px;
  right: 100px;
  height: 50px;
  align-items: center;
}

.row{
  background-color: #212338;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: blanchedalmond;
}
#profile-image{
  display: flex;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: 3px 3px 3px grey;
}
@media(max-width: 575px){
#profile-image{
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 3px 3px 3px grey;
  left: 33%;
  top: 450px;
}
.social {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100%;
}
}