.CircularProgressbar {
    width: 200px;
    height: 200px;
    margin: 25px;
    background-color: rgb(146, 155, 149);
    border-radius: 50%;
}
.CircularProgressbar-path {
    stroke: rgb(17, 0, 255);
}
.CircularProgressbar-trail {
    stroke: lightcyan;
}
.CircularProgressbar-text {
    fill: rgb(17, 0, 255);
}
.CircularProgressbar-background {
    fill: rgb(134, 163, 134);
}
.remove-bg {
    filter: brightness(1.1);
    mix-blend-mode: multiply;
  }
#row,#row_soft_skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #212338;
    justify-content: space-between;
    color: blanchedalmond;
}
#row_soft_skills {
    flex-direction: column;
}