.Home {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#video_background {
  position: absolute;
  bottom: 0px;
  right: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  overflow: hidden;
}

.Home-logo {
  height: 500px;
  width: 500px;
  pointer-events: none;
  border-radius: 250px;
}

/* .remove-bg {
  filter: brightness(1.1);
  mix-blend-mode: multiply;
} */

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 20s linear;
  }
}

.Home-header {
  /* background-color: #282c34; */
  background-position: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-radius: 80%;
}

.Home-header p {
  background-color: #282c34;
  color: rgb(208, 208, 231);
}

.Home-link {
  color: bisque;
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
